<template>
    <inner-page-card heading="Add Auction" :link-back="true" :scrollbar="true">

        <div class="container" v-if="loading">
            <div class="card bg-info">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div class="container" v-else-if="error">
            <div class="card bg-danger fl-te-c">
                <div>
                    <error-icon/>
                    Failed to load data from the server.
                </div>
                <div>
                    <a class="pointer" @click="loadData()"><i class="fa fa-refresh"></i> reload</a>
                </div>
            </div>
        </div>

        <div v-else>

            <div class="p-3 mb-3 bg-danger c-badge" v-if="errorMessage">{{ errorMessage }}</div>

            <s-form class="row" @submit="onSubmit" ref="formValidator">
                <div class="col-md-12 col-12 fl-te-c-res">
                    <div class="fs-1">
                        <span v-if="rules.is_third_party" class="fl-x">Platform Selected : <span
                            class="font-inter-medium ml-1">Other Auction</span></span>
                        <span v-else>Platform Selected : <span class="font-inter-medium ml-1"> Bank e Auctions India.com Auction</span></span>
                    </div>
                    <div v-if="currentUser.is_superuser || currentUser.is_ventura_admin" class="mt-3 mt-lg-0">
                        <btn type="button" v-if="!rules.is_third_party"
                             @click="changeAuctionType"
                             text="Create Other Auction" class="btn btn-basic-b"/>
                        <btn type="button" v-else @click="changeAuctionType"
                             text="Create Bank e Auctions India.com Auction" class="btn btn-basic-b"/>
                    </div>
                </div>
                <template v-if="rules.is_third_party">

                    <div class="col-12">
                        <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Other Details</h3>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input label="Name*" placeholder="Name"
                                         class="c-form-input c-input-2"
                                         :rules="{required: true}" name="Name"
                                         v-model="model.third_party_name" :disabled="saving"></validated-input>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input label="Contact no*" placeholder="Contact no"
                                         onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                         class="c-form-input c-input-2"
                                         :rules="{required: !rules.is_third_party, min:10, max:10}" name="Contact no*"
                                         v-model="model.third_party_contact_no" :disabled="saving"></validated-input>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input label="E-Mail*" placeholder="E-Mail"
                                         class="c-form-input c-input-2" type="email"
                                         :rules="{required: true}" name="E-Mail"
                                         v-model="model.third_party_email" :disabled="saving"></validated-input>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input label="Link" placeholder="Link" class="c-form-input c-input-2"
                                         :rules="{required: false}" name="Link"
                                         v-model="model.third_party_link" :disabled="saving"></validated-input>
                    </div>
                </template>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Auction Details</h3>
                </div>

                <div class="col-md-12 col-12">
                    <validated-input label="Title*" placeholder="Title"
                                     class="c-form-input c-input-2"
                                     :rules="{required: true}" name="Title"
                                     v-model="model.title" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <validated-text-area class="c-form-input"
                                         :label="'Description' + (!rules.is_third_party ? '*' : '')"
                                         :rules="{required: !rules.is_third_party}" name="Description"
                                         v-model="model.description" :disabled="saving"></validated-text-area>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Event Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Event Type' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input c-input-2"
                                     :rules="{required: !rules.is_third_party}" name="Event type"
                                     v-model="model.event_type" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Event No' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Event number"
                                     v-model="model.event_number" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Ref. No.' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Ref no"
                                     v-model="model.ref_no" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Tender / Event Title' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Event title"
                                     v-model="model.event_title" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-vue-select class="c-form-input"
                                          :label="'Event Bank' + (!rules.is_third_party ? '*' : '')"
                                          v-model="model.event_bank"
                                          :options="bankOptions" name="Event bank"
                                          :disabled="currentUser.is_bank_admin || currentUser.is_bank_user"
                                          :rules="{required: !rules.is_third_party}"></validated-vue-select>

                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Branch' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Branch"
                                     v-model="model.branch" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Property Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-vue-select :label="'Category' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                          class="c-form-input"
                                          :rules="{required: !rules.is_third_party}" :options="propertyOptions"
                                          @input="setSubCategory(model.property_category)" name="Category"
                                          v-model="model.property_category" :disabled="saving"></validated-vue-select>
                </div>

                <div class="col-md-6 col-12">
                    <validated-vue-select :label="'Subcategory' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                          class="c-form-input"
                                          :rules="{required: subPropertyOptions.length > 0 && !rules.is_third_party}"
                                          :options="subPropertyOptions"
                                          v-model="model.property_subcategory" name="Property Subcategory"
                                          :disabled="saving"></validated-vue-select>
                </div>

                <div class="col-12">
                    <validated-input :label="'Borrowers Name' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Borrower name"
                                     v-model="model.borrower_name" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Auction Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Reserved Price' + (!rules.is_third_party ? '*' : '')" name="Reserve price"
                                     placeholder=""
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     class="c-form-input" :rules="{required: !rules.is_third_party}"
                                     v-model="model.reverse_price" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input label="Tender Fee" placeholder=""
                                     class="c-form-input" :rules="{required: false}" name="Tender fee"
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     v-model="model.tender_fee" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input label="Price Bid ( If applicable )" placeholder="" class="c-form-input"
                                     :rules="{required: false}" name="Price bid"
                                     v-model="model.price_bid" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input :label="'Increment Value' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Increment value"
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     v-model="model.increment_value" :disabled="saving"></validated-input>
                </div>
                <div class="col-12">
                    <validated-input :label="'Auto Extension Time' + (!rules.is_third_party ? '*' : '')"
                                     placeholder="Enter how many minutes to extend"
                                     class="c-form-input"
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     :rules="{required: !rules.is_third_party, min_value:1, max_value:59}"
                                     name="Auto extension time"
                                     v-model="model.auto_extension_time" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">EMD Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'EMD Amount' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="rules.emd_amount" name="Emd amount"
                                     v-model="model.emd_amount" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-vue-select :label="'EMD Deposit Bank Name' + (!rules.is_third_party ? '*' : '')"
                                          placeholder="" class="c-form-input"
                                          :rules="{required: !rules.is_third_party}" name="Emd bank name"
                                          :options="emdBankOptions"
                                          v-model="model.emd_bank_name" :disabled="saving"></validated-vue-select>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input :label="'EMD Deposit Bank Account Number' + (!rules.is_third_party ? '*' : '')"
                                     placeholder="" class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Emd deposit bank account number"
                                     v-model="model.emd_deposit_bank_account_number"
                                     :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input :label="'EMD Deposit Bank IFSC Code' + (!rules.is_third_party ? '*' : '')"
                                     placeholder="" class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Emd deposit bank ifsc"
                                     v-model="model.emd_deposit_bank_ifsc" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Important Dates & Times</h3>
                </div>

                <div class="col-md-3 col-12">
                    <validated-date-picker label="Press Release Date" type="date"
                                           placeholder="" class="c-form-input" @input="clearInspectionDates"
                                           :rules="{required: !rules.is_third_party}" name="Press release date"
                                           v-model="model.press_release_date" :disabled-date="disableFutureDate"
                                           :disabled="saving"></validated-date-picker>
                </div>

                <div class="col-md-3 col-12">
                    <validated-input label="Press Release Time" type="time" placeholder="" @input="clearInspectionDates"
                                     :rules="{required: false}" name="Press Release Time"
                                     class="c-form-input" v-model="model.press_release_time" :disabled="saving"/>
                </div>

                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearInspectionToDate(true)"
                                           :label="'Date of Inspection of Property (From)' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder=""
                                           :rules="{required: !rules.is_third_party}"
                                           name="Property inspection from date"
                                           class="c-form-input" v-model="model.property_inspection_from_date"
                                           :disabled="saving" :disabled-date="disableInspectionFromDate"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateFromTime"
                                     :label="'Time of Inspection of Property (From)' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}"
                                     name="Property inspection from time"
                                     class="c-form-input" v-model="model.property_inspection_from_time"
                                     :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="inspectionFromTimeErrorMessage">
                        {{ inspectionFromTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearSubmissionLastDate(true)"
                                           :label="'Date of Inspection of Property (To)' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder="" :rules="{required: !rules.is_third_party}"
                                           name="Property inspection to date" class="c-form-input"
                                           v-model="model.property_inspection_to_date"
                                           :disabled="saving" :disabled-date="disableInspectionToDate"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateToTime"
                                     :label="'Time of Inspection of Property (To)' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}"
                                     name="Property inspection to time"
                                     class="c-form-input" v-model="model.property_inspection_to_time"
                                     :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="inspectionToTimeErrorMessage">
                        {{ inspectionToTimeErrorMessage }}
                    </div>
                </div>

                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearOpeningDate(true)"
                                           :label="'Offer (First Round Quote) Submission Last Date' + (!rules.is_third_party ? '*' : '')"
                                           type="date"
                                           placeholder="" :disabled-date="disableSubmissionLastDate"
                                           :rules="{required: !rules.is_third_party}" name="Offer submission last date"
                                           class="c-form-input" v-model="model.offer_submission_last_date"
                                           :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateSubmissionLastTime"
                                     :label="'Offer (First Round Quote) Submission Last Time' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}"
                                     name="Offer submission last time"
                                     class="c-form-input" v-model="model.offer_submission_last_time"
                                     :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="submissionTimeErrorMessage">
                        {{ submissionTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearAuctionStartDate(true)"
                                           :label="'Offer (First Round Quote) Opening Date' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder=""
                                           :rules="{required: !rules.is_third_party}" name="Offer opening date"
                                           :disabled-date="disableOpeningDate"
                                           class="c-form-input" v-model="model.offer_opening_date" :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateOpeningTime"
                                     :label="'Offer (First Round Quote) Opening Time' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}" name="Offer opening time"
                                     class="c-form-input" v-model="model.offer_opening_time" :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="openingTimeErrorMessage">
                        {{ openingTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearAuctionEndDate(true)"
                                           :label="'Auction Start Date' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder=""
                                           :rules="{required: !rules.is_third_party}" name="Auction start date"
                                           :disabled-date="disableAuctionStartDate"
                                           class="c-form-input" v-model="model.auction_start_date" :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input :label="'Auction Start Time' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder="" @input="validateAuctionStartTime"
                                     :rules="{required: !rules.is_third_party}" name="Auction start time"
                                     class="c-form-input" v-model="model.auction_start_time" :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="auctionStartTimeErrorMessage">
                        {{ auctionStartTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker :label="'Auction End Date' + (!rules.is_third_party ? '*' : '')" type="date"
                                           placeholder="" @input="model.auction_end_time = null"
                                           :rules="{required: !rules.is_third_party}" name="Auction end date"
                                           :disabled-date="disableAuctionEndDate"
                                           class="c-form-input" v-model="model.auction_end_date" :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input :label="'Auction End Time' + (!rules.is_third_party ? '*' : '')" type="time"
                                     placeholder="" @input="validateAuctionEndTime"
                                     :rules="{required: !rules.is_third_party}" name="Auction end time"
                                     class="c-form-input" v-model="model.auction_end_time" :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="auctionEndTimeErrorMessage">
                        {{ auctionEndTimeErrorMessage }}
                    </div>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Documents to be Uploaded</h3>
                </div>
                <div class="col-md-6 col-12">
                    <validated-file-input placeholder="NIT Documents"
                                          :label="'NIT Documents' + (!rules.is_third_party ? '*' : '')"
                                          name="Nit documents"
                                          class="c-file-input mb-1" accept=".zip"
                                          v-model="model.nit_documents" :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span class="mt-1 text-info"><sup>*</sup>
                        NIT document should be zip file format.
                    </span>
                </div>
                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 font-inter-semi-bold">Tender Documents</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-file-input :label="'Annexure 1' + (!rules.is_third_party ? '*' : '')"
                                          class="c-file-input mb-1"
                                          name="Annexure" v-model="model.annexure"
                                          :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span
                        class="mt-1 text-info"><sup>*</sup> Please upload files with extensions - <b>jpeg</b>, <b>pdf</b> or <b>docs</b> </span>
                </div>

                <div class="col-md-6 col-12">
                    <validated-file-input :label="'Bid Form' + (!rules.is_third_party ? '*' : '')"
                                          name="Bid annexure" class="c-file-input mb-1"
                                          v-model="model.bid_annexure" :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span
                        class="mt-1 text-info"><sup>*</sup> Please upload files with extensions - <b>jpeg</b>, <b>pdf</b> or <b>docs</b> </span>
                </div>

                <div class="col-12 pt-lg-4">
                    <validated-file-input :label="'Bidder Declaration Annexure' + (!rules.is_third_party ? '*' : '')"
                                          name="Bidder declaration annexure" class="c-file-input mb-1"
                                          v-model="model.bidder_declaration_annexure" :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span class="mt-1 text-info"><sup>*</sup> Please upload files with extensions - <b>jpeg</b>, <b>pdf</b> or <b>docs</b> </span>
                </div>

                <div class="col-12 mt-3">
                    <div class="fl-x fl-j-c">
                        <div class="btn-group">
                            <btn type="button" text="Cancel" class="px-5" design="basic-b" color="secondary"
                                 @click="setCancel"></btn>

                            <btn text="Create" :loading="saving" loading-text="Creating..." class="px-5"
                                 design="basic-a" color="secondary"></btn>

                        </div>
                    </div>
                </div>

            </s-form>

        </div>

    </inner-page-card>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'AddAuctionPage',

    computed : {
        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            bankOptions           : [],
            emdBankOptions        : [],
            propertyOptions       : [],
            subPropertyOptions    : [],
            allSubPropertyOptions : [],

            rules : {
                is_third_party : false,
                emd_amount     : {
                    customValidator : (value) => {
                        return this.CustomAmountValidate(value);
                    }
                }
            },

            model : {
                title                           : '',
                description                     : '',
                event_type                      : '',
                ref_no                          : '',
                event_number                    : '',
                event_title                     : '',
                event_bank                      : '',
                branch                          : '',
                property_category               : '',
                property_subcategory            : '',
                borrower_name                   : '',
                reverse_price                   : '',
                tender_fee                      : '',
                price_bid                       : '',
                increment_value                 : '',
                auto_extension_time             : '',
                emd_bank_name                   : '',
                emd_amount                      : '',
                emd_deposit_bank_account_number : '',
                emd_deposit_bank_ifsc           : '',
                press_release_date              : '',
                press_release_time              : '',
                property_inspection_from_date   : '',
                property_inspection_from_time   : '',
                property_inspection_to_date     : '',
                property_inspection_to_time     : '',
                offer_submission_last_date      : '',
                offer_submission_last_time      : '',
                offer_opening_date              : '',
                offer_opening_time              : '',
                auction_start_date              : '',
                auction_start_time              : '',
                auction_end_date                : '',
                auction_end_time                : '',
                pancard                         : '',
                declaration_form                : '',
                proof_of_address                : '',
                nit_documents                   : '',
                annexure                        : '',
                bid_annexure                    : '',
                bidder_declaration_annexure     : '',
                is_third_party                  : '',
                third_party_name                : '',
                third_party_contact_no          : '',
                third_party_email               : '',
                third_party_link                : ''
            },

            errorMessage                   : '',
            inspectionFromTimeErrorMessage : '',
            inspectionToTimeErrorMessage   : '',
            submissionTimeErrorMessage     : '',
            openingTimeErrorMessage        : '',
            auctionStartTimeErrorMessage   : '',
            auctionEndTimeErrorMessage     : '',
            loading                        : true,
            saving                         : false,
            error                          : false
        };
    },

    watch : {
        'rules.is_third_party' : {
            handler () {
                this.setAuctionFormErrors();
            }
        }
    },

    mounted () {
        this.loadData();
        this.setInitials();
    },

    methods : {
        changeAuctionType () {
            this.setInitials();
            this.rules.is_third_party = !this.rules.is_third_party;
        },

        setAuctionFormErrors () {
            const formValidator = this.$refs.formValidator;
            formValidator.reset();
        },

        setInitials () {
            this.model = {
                auction_start_date              : '',
                auction_start_time              : '',
                title                           : '',
                description                     : '',
                event_type                      : '',
                ref_no                          : '',
                event_number                    : '',
                event_title                     : '',
                event_bank                      : this.currentUser.bank?.id || '',
                branch                          : '',
                property_category               : '',
                property_subcategory            : '',
                borrower_name                   : '',
                reverse_price                   : '',
                tender_fee                      : '',
                price_bid                       : '',
                increment_value                 : '',
                auto_extension_time             : '',
                emd_bank_name                   : '',
                emd_amount                      : '',
                emd_deposit_bank_account_number : '',
                emd_deposit_bank_ifsc           : '',
                press_release_date              : '',
                property_inspection_from_date   : '',
                property_inspection_to_date     : '',
                offer_submission_last_date      : '',
                offer_opening_date              : '',
                press_release_time              : '00:00',
                property_inspection_from_time   : '',
                property_inspection_to_time     : '',
                offer_submission_last_time      : '',
                offer_opening_time              : '',
                auction_end_date                : '',
                auction_end_time                : '',
                pancard                         : '',
                declaration_form                : '',
                proof_of_address                : '',
                nit_documents                   : '',
                annexure                        : '',
                bid_annexure                    : '',
                bidder_declaration_annexure     : '',
                is_third_party                  : '',
                third_party_name                : '',
                third_party_contact_no          : '',
                third_party_email               : '',
                third_party_link                : ''
            };
        },

        CustomAmountValidate (value) {
            const givenAmount = value;
            if (parseInt(givenAmount) > parseInt(this.model.price_bid)) {
                return 'EMD Amount should be less than Price Bid Amount';
            } else {
                return true;
            }
        },

        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;

            axios.get(urls.auction.auction.formInitialization + '?type=Text').then(function (data) {
                const json = data.data;
                that.propertyOptions = [...json.property_type.data];
                that.bankOptions = [...json.bank.data];
                that.emdBankOptions = [...json.emd_bank.data];
                that.subPropertyOptions = [...json.property_sub_category.data];
                that.allSubPropertyOptions = [...json.property_sub_category.data];
                that.loading = false;
            }).catch(function (exception) {
                console.log('exception occurred at the add auction : ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        setCancel () {
            this.$router.push({ path : '/auctions-list/' });
        },

        setSubCategory (item) {
            this.model.property_subcategory = '';
            if (item) {
                const filterValues = this.allSubPropertyOptions.filter(obj => obj.property_type === item);
                this.subPropertyOptions = [...filterValues];
            } else {
                this.subPropertyOptions = [...this.allSubPropertyOptions];
            }
        },

        onSubmit () {
            const conform = confirm('Are You Sure ?');

            if (!conform) {
                return;
            }

            const that = this;
            that.saving = true;
            const formValidator = that.$refs.formValidator;
            that.model.is_third_party = that.rules.is_third_party;

            axios.form(urls.auction.auction.addEdit, that.model).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    that.saving = false;
                    that.$notify('Successfully Added Auction..!', 'Success',
                        {
                            type : 'success'
                        }
                    );
                    that.$router.push({ path : '/auction/' + json.id + '/details/' });
                } else {
                    that.saving = false;
                    that.errorMessage = json.errors.__all__;
                    that.$notify('Form error found..!', 'Error',
                        {
                            type : 'warning'
                        }
                    );
                    formValidator.setErrors(json.errors);
                }
            }).catch(function (exception) {
                console.log('exception occurred at the add auction : ', exception);
                that.$notify('Server Error..!', 'Error',
                    {
                        type      : 'warning',
                        duration  : 3000,
                        permanent : false
                    }
                );
                that.saving = false;
            });
        },

        disablePastDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },

        disableFutureDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            return date > yesterday;
        },

        validateTimeRange (fromDate, fromTime, toDate, toTime, errorKey, errorMessage) {
            this[errorKey] = '';

            if (fromDate && fromTime && toDate && toTime) {
                const formatDateString = (dateString) => {
                    const [day, month, year] = dateString.split('/');
                    return `${year}-${month}-${day}`;
                };

                const fromDateTime = new Date(`${formatDateString(fromDate)} ${fromTime}`);
                const toDateTime = new Date(`${formatDateString(toDate)} ${toTime}`);

                if (toDateTime <= fromDateTime) {
                    this[errorKey] = `${errorMessage}`;
                }
            }
        },

        isDateDisabled (targetDate, currentDate) {
            if (targetDate) {
                const parts = targetDate.split('/');
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1;
                const year = parseInt(parts[2], 10);
                const target = new Date(year, month, day);
                target.setHours(0, 0, 0, 0);

                if (target < new Date()) {
                    return currentDate < new Date();
                } else {
                    return currentDate < target;
                }
            } else {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return currentDate < today;
            }
        },

        disableDatesMainFunction (date, fromDate) {
            const today = new Date();
            const fromDateParts = fromDate.split('/');
            const formattedFromDate = new Date(
                fromDateParts[2], fromDateParts[1] - 1, fromDateParts[0]
            );

            if (formattedFromDate > today) {
                return this.isDateDisabled(fromDate, date);
            } else {
                return this.disablePastDate(date);
            }
        },

        clearDates (dateA, timeA, timeB, errorField, clearAll) {
            this.model[dateA] = null;
            this.model[timeA] = null;
            if (clearAll) {
                this.model[timeB] = null;
            }
            this[errorField] = '';
        },

        clearInspectionDates () {
            this.clearInspectionFromDate();
            this.clearInspectionToDate();
            this.clearSubmissionLastDate();
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
        },

        clearInspectionFromDate (clearAll) {
            this.clearInspectionToDate();
            this.clearSubmissionLastDate();
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'property_inspection_from_date',
                'property_inspection_from_time',
                'press_release_time',
                'inspectionFromTimeErrorMessage',
                clearAll
            );
        },

        clearInspectionToDate (clearAll) {
            this.clearSubmissionLastDate();
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'property_inspection_to_date',
                'property_inspection_to_time',
                'property_inspection_from_time',
                'inspectionToTimeErrorMessage',
                clearAll
            );
        },

        clearSubmissionLastDate (clearAll) {
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'offer_submission_last_date',
                'offer_submission_last_time',
                'property_inspection_to_time',
                'submissionTimeErrorMessage',
                clearAll
            );
        },

        clearOpeningDate (clearAll) {
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'offer_opening_date',
                'offer_opening_time',
                'offer_submission_last_time',
                'openingTimeErrorMessage',
                clearAll
            );
        },

        clearAuctionStartDate (clearAll) {
            this.clearAuctionEndDate();
            this.clearDates(
                'auction_start_date',
                'auction_start_time',
                'offer_opening_time',
                'auctionStartTimeErrorMessage',
                clearAll
            );
        },

        clearAuctionEndDate (clearAll) {
            this.clearDates(
                'auction_end_date',
                'auction_end_time',
                'auction_start_time',
                'auctionEndTimeErrorMessage',
                clearAll
            );
        },

        disableInspectionFromDate (date) {
            return this.disableDatesMainFunction(date, this.model.press_release_date);
        },

        disableInspectionToDate (date) {
            return this.disableDatesMainFunction(date, this.model.property_inspection_from_date);
        },

        disableSubmissionLastDate (date) {
            return this.disableDatesMainFunction(date, this.model.property_inspection_to_date);
        },

        disableOpeningDate (date) {
            return this.disableDatesMainFunction(date, this.model.offer_submission_last_date);
        },

        disableAuctionStartDate (date) {
            return this.disableDatesMainFunction(date, this.model.offer_opening_date);
        },

        disableAuctionEndDate (date) {
            return this.disableDatesMainFunction(date, this.model.auction_start_date);
        },

        validateFromTime () {
            this.clearInspectionToDate(false);
            this.validateTimeRange(
                this.model.press_release_date,
                this.model.press_release_time,
                this.model.property_inspection_from_date,
                this.model.property_inspection_from_time,
                'inspectionFromTimeErrorMessage',
                'Inspection from time should be greater than press release time'
            );
        },

        validateToTime () {
            this.clearSubmissionLastDate(false);
            this.validateTimeRange(
                this.model.property_inspection_from_date,
                this.model.property_inspection_from_time,
                this.model.property_inspection_to_date,
                this.model.property_inspection_to_time,
                'inspectionToTimeErrorMessage',
                'Inspection to time should be greater than Inspection from time'
            );
        },

        validateSubmissionLastTime () {
            this.clearOpeningDate(false);
            this.validateTimeRange(
                this.model.property_inspection_to_date,
                this.model.property_inspection_to_time,
                this.model.offer_submission_last_date,
                this.model.offer_submission_last_time,
                'submissionTimeErrorMessage',
                'Submission last time should be greater than Inspection to time'
            );
        },

        validateOpeningTime () {
            this.clearAuctionStartDate(false);
            this.validateTimeRange(
                this.model.offer_submission_last_date,
                this.model.offer_submission_last_time,
                this.model.offer_opening_date,
                this.model.offer_opening_time,
                'openingTimeErrorMessage',
                'Opening time should be greater than Submission last time'
            );
        },

        validateAuctionStartTime () {
            this.clearAuctionEndDate(false);
            this.validateTimeRange(
                this.model.offer_opening_date,
                this.model.offer_opening_time,
                this.model.auction_start_date,
                this.model.auction_start_time,
                'auctionStartTimeErrorMessage',
                'Auction start time should be greater than Opening time'
            );
        },

        validateAuctionEndTime () {
            this.validateTimeRange(
                this.model.auction_start_date,
                this.model.auction_start_time,
                this.model.auction_end_date,
                this.model.auction_end_time,
                'auctionEndTimeErrorMessage',
                'Auction end time should be greater than Auction start time'
            );
        }

    }
};
</script>

<style scoped>

</style>
